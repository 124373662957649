import { Link, Routes, Route, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import UpComingEvents from "./eventComponents/upComingEvents/UpCommingEvents";
import SuccessFullEvent from "./eventComponents/successFullEvent/SuccessFullEvent";
import { NewEventCard } from "./eventComponents/NewEventCard";
import MetaTagHelmet from "../../globalComponents/MetaTagHelmet";

import useApiFetcher from "../../../hooks/useApiFetcher";
import useScrollOnTop from "../../../hooks/useScrollOnTop";
import { getEventType } from "../../../services/businessLogic";
import { API_URL, getFullUrl } from "../../../assets/constants/apiUrls";
import { EVENT_TYPE } from "../../../assets/constants/eventPage";
import URL from "../../../assets/constants/url";
import "./event.css";

function getEventDataByRoute(eventsData, route) {
  const UPCOMING_EVENTS = eventsData?.filter((event) =>
    getEventType(event?.attributes?.Start_Date)
  );

  const SUCCESSFUL_EVENTS = eventsData?.filter(
    (event) => !getEventType(event?.attributes?.Start_Date)
  );

  const eventDataMap = {
    "/event/upcoming-event": {
      data: UPCOMING_EVENTS,
      eventType: "Upcoming Events",
    },
    "/event/successfull-event": {
      data: SUCCESSFUL_EVENTS,
      eventType: "Successful Events",
    },
  };

  return eventDataMap[route] || eventDataMap["/event/upcoming-event"];
}

const url = getFullUrl(`${API_URL.EVENT}?populate=*`);

const Event = () => {
  const { pathname, search } = useLocation();
  const [eventData, setEventData] = useState([]);
  const [activeBtnColor, setActiveBtnColor] = useState(
    EVENT_TYPE.upComingEvent
  );

  const [events, error, isLoading] = useApiFetcher(url);

  useScrollOnTop(400, 0, pathname + search);

  useEffect(() => {
    const defaultPath =
      pathname === "/event" ? "/event/upcoming-event" : pathname;
    setEventData(getEventDataByRoute(events, defaultPath));
  }, [pathname, events]);

  if (error) return <div>{error.message}</div>;
  if (!eventData && isLoading) return <div>Loading...</div>;

  const btnColorSet = (btnType) =>
    activeBtnColor === btnType ? "#ef750f" : "#0b2c3d";

  return (
    <>
      <MetaTagHelmet />
      <div className="container px-5 py-0 mx-auto">
        <div className="eventHeader col-md-12 ">
          <div
            className="col-md-3 events"
            style={{
              backgroundColor: btnColorSet(EVENT_TYPE.upComingEvent),
            }}
          >
            <Link to={URL.UPCOMING_EVENT} state={eventData}>
              Upcoming Events
            </Link>
          </div>
          <div
            className="col-md-3 events"
            style={{
              backgroundColor: btnColorSet(EVENT_TYPE.successFullEvent),
            }}
          >
            <Link to={URL.SUCCESS_FULL_Event} state={eventData}>
              Past Events
            </Link>
          </div>
        </div>

        <div className="row p-3 p-lg-5 p-md-5 eventCardContainer ">
          {eventData?.data?.map((item, idx) => {
            const { Start_Date } = item?.attributes;
            const eventStatus = getEventType(Start_Date)
              ? "upcoming-event"
              : "successfull-event";

            return (
              <Link
                key={idx}
                to={`/event/${eventStatus}/eventname`}
                className="col-12 col-lg-4 col-md-4 p-0 m-0"
                state={item?.id}
              >
                <NewEventCard cardData={item} />
              </Link>
            );
          })}
        </div>

        <Routes>
          <Route path={URL.UPCOMING_EVENT} element={<UpComingEvents />} />
          <Route path={URL.SUCCESS_FULL_Event} element={<SuccessFullEvent />} />
        </Routes>
      </div>
    </>
  );
};

export default Event;

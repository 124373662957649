import AddressCardNew from "./AddressCardNew";

import useApiFetcher from "../../../../hooks/useApiFetcher";
import useDeviceSize from "../../../../hooks/useDeviceSize";
import { getFullUrl, API_URL } from "../../../../assets/constants/apiUrls";
// import { ADDRESSES } from "../../../../assets/constant/contactUs";

const AddressSection = () => {
  const url = getFullUrl(`${API_URL.CONTACT_US}?populate=*`);
  const isMobile = useDeviceSize();
  const [contactUs, error, isLoading] = useApiFetcher(url);

  if (error) return <div>{error.message}</div>;
  if (!contactUs || isLoading) return null;

  const ADDRESSES = contactUs?.attributes?.Branches;
  // console.log("addressessssss", ADDRESSES);

  return isMobile ? (
    <div>
      <div className="row center-item">
        {ADDRESSES?.map((cardData, idx) => (
          <AddressCardNew data={cardData} key={idx} heading="Noida" />
        ))}
      </div>
    </div>
  ) : (
    <div className="row center-item m-3 mb-5">
      {ADDRESSES?.map((cardData, idx) => (
        <AddressCardNew data={cardData} key={idx} heading="Lucknow" />
      ))}
    </div>
  );
};
export default AddressSection;

import CkEditorContentDisplay from "../../globalComponents/CkEditorContentDisplay/CkEditorContentDisplay";
import "../blogOverview/blogOverview.css";

const EventSideSummary = ({ description }) => {
  return (
    <section className="blogPad">
      {description?.map((item, idx) => {
        return <CkEditorContentDisplay key={idx} content={item} />;
      })}
    </section>
  );
};

export default EventSideSummary;

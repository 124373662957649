import Heading from "../../../globalComponents/molecules/Heading";
import "./sideContainer.css";
import { LuCalendarDays } from "react-icons/lu";

const RecentPostSideCard = ({ data }) => {
  const { img, title, date } = data;

  return (
    // <div className="col-md-12 my-1 bg-white border border-2">
    //   <div className="row " style={{ height: "100px" }}>
    //     <div
    //       className="col-6 col-6   border border-2"
    //       style={{ height: "100%" }}
    //     >
    //       <img src={img} alt="" className=" rounded-start img-fluid" />
    //     </div>
    //     <div className="col-6 col-6  pt-2 ">
    //       <div className="d-flex gap-2 align-items-center">
    //         <LuCalendarDays style={{ color: "#ef750f" }} />
    //         <Heading text={date} fontSize=".75rem" color="black" />
    //       </div>
    //       <Heading text={category} fontSize=".75rem" color="#ef750f" />

    //       <Heading
    //         text={title}
    //         fontSize=".75rem"
    //         color="black"
    //         lineHeight="1rem"
    //         showLine="2"
    //         fontWeight="500"
    //         className="text-truncate"
    //       />
    //       <p className="text-black  blog-recent-post">{title}</p>
    //     </div>
    //   </div>
    // </div>
    <div className="  d-flex flex-column my-2 ">
      <div
        className="d-flex  row m-0 border border-2 rounded bg-white  recent-blog-post"
        // style={{ height: "80px" }}
      >
        <div className="col-6 p-0  rounded-start h-100">
          <img
            src={img}
            alt=""
            className="rounded-start  img-fluid h-100 w-100"
          />
        </div>
        <div className="col-6 p-0 px-1 d-flex  flex-column gap-2 pt-2">
          <div className="d-flex gap-2 align-items-center flex-start">
            <LuCalendarDays style={{ color: "#ef750f", fontSize: "13px" }} />
            <Heading text={date} fontSize=".6rem" color="black" />
          </div>
          <p className="text-black  blog-recent-post-text">{title}</p>
          {/* <div>
            <Heading
              text={title}
              fontSize=".75rem"
              color="black"
              lineHeight="1rem"
              showLine="2"
              fontWeight="500"
              className="text-truncate"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default RecentPostSideCard;

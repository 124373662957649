import "react-phone-input-2/lib/style.css";
import "./QuerySectionTemplate.css";
import QueryForm2 from "../../globalComponents/queryForm2/QueryForm2";
// import Emoji from "../../../assets/emoji.gif";

const QuerySectionTemplate = () => {
  // const [phone, setPhone] = useState("");
  // const [name, setName] = useState("");
  // const [submitted, setSubmitted] = useState(false);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setSubmitted(true);
  // };

  return (
    <div className="m-0 my-5 QuerySectionTemplate">
      <div className="container hover-section">
        <div className="row d-flex justify-content-center">
          <div className="col-md-6">
            <h1 className=" responsive-heading">
              Let Us Help You Discover Your Ideal Space
            </h1>
          </div>
          <div className="col-md-5 mt-4 ">
            <QueryForm2 />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuerySectionTemplate;

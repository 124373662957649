import React, { useCallback, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { FORM_NAMES } from "../../../assets/constants/formNames";
import { submissionOnZoho } from "../../../assets/constants/zoho";
import { PROCESS_MESSAGE } from "../../../assets/constants/messages";
import ThankYouQuery2 from "./ThankYouQuery2";

const QueryForm2 = () => {
  const [formName] = useState(FORM_NAMES?.ANY_ENQUIRIES_FORM_NAME);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

  const validateForm = () => {
    const validationErrors = {};

    if (!name.trim()) {
      validationErrors.name = "Full Name is required";
    }

    if (!phone.trim()) {
      validationErrors.phone = "Mobile Number is required";
    } else if (!/^\d{12}$/.test(phone.replace(/\D/g, ""))) {
      validationErrors.phone = "Mobile Number must be 10 digits";
    }

    return validationErrors;
  };

  const handleFormSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length === 0) {
        setIsBtnDisable(true);
        await submissionOnZoho({ formName, name, phone });
        setIsBtnDisable(false);
        setIsSuccessfullySubmitted(true);
        setName("");
        setPhone("");
      } else {
        setErrors(validationErrors);
      }
    },
    [formName, name, phone]
  );

  const handleThankYouComplete = () => setIsSuccessfullySubmitted(false);

  return (
    <div
      className="p-4"
      style={{
        backgroundColor: "rgba(38, 38, 38, 0.77)",
        borderRadius: "15px",
        width: "100%",
        color: "white",
      }}
    >
      <h2
        className="text-center mb-4"
        style={{ fontWeight: "bold", fontFamily: "Poppins" }}
      >
        Contact Us
      </h2>
      {isSuccessfullySubmitted ? (
        <ThankYouQuery2 onComplete={handleThankYouComplete} />
      ) : (
        <form onSubmit={handleFormSubmit}>
          <div>
            <input
              name="name"
              type="text"
              required
              className="border-bottom border-none text-white mb-3 pb-1 bg-transparent"
              value={name}
              placeholder="Name"
              style={{
                width: "100%",
                border: "none",
                borderBottom: "1px solid white",
              }}
              onChange={(e) => setName(e.target.value)}
            />
            {errors.name && <span className="alertMsg">{errors.name}</span>}
          </div>

          <div className="input-group">
            <PhoneInput
              className="border-bottom border-none text-white mb-3 pb-1"
              name="phone"
              country={"in"}
              value={phone}
              onChange={(phone) => setPhone(phone)}
              // enableSearch={true}
              // disableSearchIcon={true}
              // disableCountryCode={true}
              countryCodeEditable={false}
              placeholder=""
              style={{
                background: "none",
                width: "100%",
                border: "none",
                borderBottom: "1px solid white",
              }}
            />
            {errors.phone && (
              <span className="alertMsg my-1 mb-3">{errors.phone}</span>
            )}
          </div>

          <button
            type="submit"
            className="btn p-2 w-100"
            style={{
              backgroundColor: "#666",
              color: "white",
              fontWeight: "bold",
              borderRadius: "25px",
            }}
            disabled={isBtnDisable || !phone || !name}
          >
            {isBtnDisable
              ? PROCESS_MESSAGE.FORM_SUBMISSION_IN_PROGRESS
              : "Submit"}
          </button>
        </form>
      )}
    </div>
  );
};

export default QueryForm2;

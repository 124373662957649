import React, { useEffect } from "react";
// import heroImg from "./assets/newspage_heroimg.png";
// import bjpImg from "./assets/newpage_bjpimg.png";
// import trendingImg1 from "./assets/trendingnews_img1.png";
// import trendingImg2 from "./assets/trendingnews_img2.png";
// import trendingImg3 from "./assets/trendingnews_img3.png";
// import trendingImg4 from "./assets/trendingnews_img4.png";
// import articleImg from "./assets/articles_img1.png";
// import articleUser from "./assets/article_user1.png";
// import slidingNews1 from "./assets/sliding_news1.png";
import axios from "axios";

import "./NewsPage.css";
import { useState } from "react";
// import CustomCarousel from "./Carousel";
import { Link } from "react-router-dom";
import Loader from "../../Loader/Loader";

const NewsPage = () => {
  // const newsItems = [
  //   {
  //     title: "6-Year-Old Horse Dies at Belmont Park After Race Injury",
  //     date: "03 June 2023",
  //     author: "Race98",
  //     description:
  //       "NEW YORK—A 6-year-old horse died after being injured in a race at Belmont Park ahead of next week's.",
  //     image: trendingImg1,
  //   },
  //   {
  //     title: "Savilia Blunk Embraces Longer Season With World Cup",
  //     date: "03 June 2023",
  //     author: "Jony.Ls",
  //     description:
  //       "Last year, Savilia Blunk took a more conservative approach to her first season as an Elite Class athlete, skipping some.",
  //     image: trendingImg2,
  //   },
  //   {
  //     title: "Ryan Garcia is fighting again, this time on social media",
  //     date: "03 June 2023",
  //     author: "King.F",
  //     description:
  //       "Boxing star Ryan Garcia and his promoter, Hall of Fame fighter Oscar De La Hoya, reignited their war of words via Twitter on.",
  //     image: trendingImg3,
  //   },
  // ];
  // const articles = [
  //   {
  //     title:
  //       "5 Exercises Basketball Players Should Be Using To Develop Strength",
  //     author: "Jake Will.",
  //     date: "04 June 2023",
  //     description:
  //       "This article was written by Jake Willhoite from Healthlisted.com Strength in basketball isn’t all about a massive body mass or ripped muscles.",
  //     image: articleImg,
  //     authorImage: articleUser,
  //   },
  //   {
  //     title:
  //       "Golden Knights out to fulfill owner's quest to win Stanley Cup in 6th year",
  //     author: "Foxi.zacon",
  //     date: "03 June 2023",
  //     description:
  //       "The Vegas Golden Knights will play the Florida Panthers in the Stanley Cup Final beginning Saturday.",
  //     image: articleImg,
  //     authorImage: articleUser,
  //   },
  //   {
  //     title: "‘Outdoor’ Badminton Gets Support From Local Federation",
  //     author: "Bong Lozada",
  //     date: "01 June 2023",
  //     description:
  //       "The Badminton World Federation is developing Air Badminton and the country’s governing body, Philippine Badminton Association.",
  //     image: articleImg,
  //     authorImage: articleUser,
  //   },
  //   {
  //     title: "The Future of Real Estate: Predictions for 2025",
  //     author: "Emily Jane",
  //     date: "05 June 2023",
  //     description:
  //       "Experts discuss how real estate trends might evolve in the coming years with technology and innovation.",
  //     image: articleImg,
  //     authorImage: articleUser,
  //   },
  //   {
  //     title: "How to Invest in Real Estate with Minimal Risk",
  //     author: "Michael Dow",
  //     date: "02 June 2023",
  //     description:
  //       "Learn the strategies to invest in real estate while minimizing risk and maximizing returns.",
  //     image: articleImg,
  //     authorImage: articleUser,
  //   },
  //   {
  //     title: "How to Invest in Real Estate with Minimal Risk",
  //     author: "Michael Dow",
  //     date: "02 June 2023",
  //     description:
  //       "Learn the strategies to invest in real estate while minimizing risk and maximizing returns.",
  //     image: articleImg,
  //     authorImage: articleUser,
  //   },
  // ];
  // const slides = [
  //   {
  //     id: 0,
  //     src: slidingNews1,
  //     alt: "Slide 1",
  //     label: "Slide 1",
  //   },
  //   {
  //     id: 1,
  //     src: slidingNews1,
  //     alt: "Slide 2",
  //     label: "Slide 2",
  //   },
  //   {
  //     id: 2,
  //     src: slidingNews1,
  //     alt: "Slide 3",
  //     label: "Slide 3",
  //   },
  // ];
  const [newses, setNewses] = useState([]);
  // const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchNews = async () => {
      setLoading(true); // Start loading
      setError(null); // Reset error state
      try {
        const response = await axios.get(
          "https://adsrole-wc-reality-web-crawler.vercel.app/api/news"
        );
        setNewses(response.data.data?.reverse() || []); // Update state with reversed data
      } catch (err) {
        console.error("Error fetching news:", err);
        setError("Failed to fetch news. Please try again later.");
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchNews();
  }, []);
  // console.log("newsss", newses);

  // const handleNext = () => {
  //   if (currentIndex + 3 < articles.length) {
  //     setCurrentIndex(currentIndex + 3);
  //   }
  // };

  // const handlePrev = () => {
  //   if (currentIndex - 3 >= 0) {
  //     setCurrentIndex(currentIndex - 3);
  //   }
  // };
  return (
    <div className="container my-5 newspage">
      {/* Top Section */}
      <div className="row align-items-center mb-5">
        <div className="col-md-6 text-center text-md-start">
          {/* <h1 className="fw-bold mb-3">
            NEW YORK 2024 <br />
            FASHION WEEK, <br />
            MINAKHADJI EVA
          </h1> */}
          <h1>{newses[19]?.title}</h1>

          <p className="text-muted">{newses[19]?.summary}</p>
          <Link
            to={newses[19]?.link}
            className="btn btn-dark mt-3 mb-3 mb-lg-0"
          >
            CONTINUE READING
          </Link>
        </div>
        <div className="col-md-6 text-center">
          <img
            src={newses[19]?.imageUrl}
            alt="Fashion Week"
            className="img-fluid rounded"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>

      {/* Bottom Section */}
      {/* <div className="mt-5">
        <h2 className="fw-bold mb-4">
          Now, Big BJP brother and Devendra Bigg boss
        </h2>
        <div className="row">
          <div className="col-md-4">
            <p className="text-muted">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est
              laborum."nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit anim id est
              laborum."nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit a orum."
            </p>
          </div>
          <div className="col-md-4  d-flex flex-column ">
            <img
              src={bjpImg}
              alt="Building"
              className="img-fluid rounded mb-3"
            />
            <p
              className="text-muted p-1"
              style={{ backgroundColor: "#EBEEF3" }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in"
            </p>
          </div>
          <div className="col-md-4">
            <p className="text-muted">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est
              laborum."nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit anim id est
              laborum."nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit a orum."
            </p>
          </div>
        </div>
      </div> */}

      {/* <div className="mt-5">
        <h2 className="fw-bold mb-4">Trending News</h2>

        <div className=" d-flex flex-column flex-md-row">
          <div className="trendingnewssection">
            <div className="p-3  rounded  ">
              {newsItems.map((news, index) => (
                <div key={index} className="d-flex mb-4">
                  <img
                    src={news.image}
                    alt={news.title}
                    className="img-fluid rounded me-3"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                  <div>
                    <p className="mb-1 text-muted small">
                      {news.author} — {news.date}
                    </p>
                    <h5 className="mb-2 fw-bold">{news.title}</h5>
                    <p className="mb-0 text-muted">{news.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className=" text-center">
            <img
              src={trendingImg4}
              alt="House Image"
              className="img-fluid rounded"
            />
          </div>
        </div>
      </div> */}

      <div className="mt-5">
        <h2 className="fw-bold mb-4 fs-3 fs-md-1 text-center text-lg-start">
          Real Estate Articles
        </h2>

        {loading && <Loader />}
        {error && <p className="text-danger">Error: {error}</p>}

        <div className="row g-3">
          {!loading && !error && newses?.length > 0
            ? newses.map((article, index) => (
                <div
                  key={index}
                  className="col-12 col-sm-6 col-md-4 news-parent-box "
                >
                  <Link
                    className="border  p-3 h-100 d-flex flex-column rounded text-black underline-none text-decoration-none"
                    to={article?.link}
                    target="_blank"
                  >
                    <div>
                      <img
                        src={article?.imageUrl}
                        alt={article?.title}
                        className="w-100 rounded mb-3"
                        style={{
                          objectFit: "cover",
                          maxHeight: "200px",
                        }}
                      />
                    </div>
                    <h5 className="mb-3 news-child ">{article?.title}</h5>
                    <p className="mt-auto">{article?.source}</p>
                    {/* Uncomment the line below to display the article summary */}
                    {/* <p className="text-muted">{article?.summary}</p> */}
                  </Link>
                </div>
              ))
            : !loading &&
              !error && (
                <p className="text-center w-100">
                  No articles available at the moment.
                </p>
              )}
        </div>
      </div>
      {/* <CustomCarousel /> */}
    </div>
  );
};

export default NewsPage;

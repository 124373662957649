import { toast } from "react-toastify";
import { FaHandshake } from "react-icons/fa6";
import { useCallback, useMemo, useState } from "react";

import Heading from "./../../../../globalComponents/molecules/Heading";
import ButtonDarkBlue from "../../../../globalComponents/molecules/ButtonDarkBlue";
import SelectOption from "../../../../globalComponents/molecules/SelectOption";
import InputCustom from "../../../../globalComponents/molecules/InputCustom";
import PopUpCard from "../../../../globalComponents/PopUpCard/PopUpCard";
import ThankYouMessage from "../../../../globalComponents/ThankYouMessage";

// import validateForm from "./validation.helper";
// import { postMethod } from "../../../../../services";
import { EMPTY_OBJECT } from "../../../../../assets/constants";
// import { API_URL, getFullUrl } from "../../../../../assets/constants/apiUrls";
import CAREER_FORM from "./careerOptions.constant";
import {
  PROCESS_MESSAGE,
  // SUCCESS_MESSAGES,
  ERROR_MESSAGE,
  // SUCCESS_MESSAGES,
} from "../../../../../assets/constants/messages";
import { closeBtnStyle } from "../../../../styles/globalStyle";
import "./careerForm.css";
import axios from "axios";
import validateCareerForm from "./validateCarrerForm-helper";

const popPupStyle = {
  flexDirection: "column",
  top: 0,
  left: 0,
  height: "100%",
};

const CareerFormPopUp = ({ isOpen, onClose, formName }) => {
  const INITIAL_FORM_VALUES = useMemo(
    () => ({
      SingleLine: "",
      PhoneNumber_countrycode: "",
      Email: "",
      SingleLine1: "",
      Dropdown: "",
      Currency1: "",
      Currency: "",
      Dropdown3: "",
      SingleLine2: formName.trim(),
      ImageUpload: null,
      FileUpload: null,
      // SingleLine: "",
      // PhoneNumber_countrycode: "",
      // Email: "",
      // location: "",
      // expectedPackage: "",
      // currentPackage: "",
      // experience: "",
      // preferLocation: "",
      // jobTitle: formName.trim(),
      // photo: null,
      // file: null,
    }),
    [formName]
  );

  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);
  const [errors, setErrors] = useState({});
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  // console.log('FormName',formName);

  const {
    SingleLine,
    PhoneNumber_countrycode,
    Email,
    SingleLine1,
    Dropdown,
    Currency1,
    Currency,
    Dropdown3,
    // ImageUpload,
    // FileUpload,
  } = formValues || EMPTY_OBJECT;

  // const handleForm2 = useCallback(
  //   async (event) => {
  //     event.preventDefault();

  //     const postUrl="https://forms.zohopublic.com/wealthclinic/form/RecruitWebsite/formperma/6l1CQJLVvQipD4Ca4qP40re9Di3w1dDIRuHO7kTpB1s/htmlRecords/submit"

  //     console.log('posturl',postUrl);

  //     const formData = new FormData();
  //     const payLoad = { ...formValues };
  //     formData.append("data", JSON.stringify(payLoad));
  //     if (formValues.ImageUpload) {
  //       formData.append("ImageUpload", formValues.ImageUpload);
  //     }
  //     if (formValues.FileUpload) {
  //       formData.append("FileUpload", formValues.FileUpload);
  //     }

  //     try {
  //       setIsBtnDisable(true);
  //       // const response = await postMethod(
  //       //   postUrl,
  //       //   formData,
  //       //   SUCCESS_MESSAGES.CAREER_FORM_SUBMISSION
  //       // );
  //       const response = await axios.post(
  //         postUrl,
  //         {SingleLine,
  //           PhoneNumber_countrycode,
  //           Email,
  //           SingleLine1,
  //           Dropdown,
  //           Currency1,
  //           Currency,
  //           Dropdown3,
  //           ImageUpload,
  //           FileUpload,},
  //         SUCCESS_MESSAGES.CAREER_FORM_SUBMISSION
  //       );

  //       // const response = await fetch(postUrl, {
  //       //   method: "POST",
  //       //   body: allFieldFormData,
  //       // });
  //       if (response) {
  //         console.log('this is response',response);

  //         toast.success("Career Form Submitted");

  //         setIsBtnDisable(false);
  //         setIsSuccessfullySubmitted(true);
  //         setFormValues(INITIAL_FORM_VALUES);
  //       }
  //     } catch (error) {
  //       toast.error(ERROR_MESSAGE.CAREER_FORM_SUBMISSION);
  //       setIsSuccessfullySubmitted(true);
  //       setFormValues(INITIAL_FORM_VALUES);
  //       setIsBtnDisable(false);
  //     }
  //   },
  //   [formValues, INITIAL_FORM_VALUES, FileUpload, ImageUpload]
  // );
  const handleForm = useCallback(
    async (event) => {
      event.preventDefault();
      const validationErrors = validateCareerForm(formValues);
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
      setErrors({});
      // const postUrl = "https://adsrole-wc-reality-web-crawler.vercel.app/proxy/form/submit";
      // const postUrl="https://forms.zohopublic.com/wealthclinic/form/RecruitWebsite/formperma/6l1CQJLVvQipD4Ca4qP40re9Di3w1dDIRuHO7kTpB1s/htmlRecords/submit"
      const postUrl = process.env.REACT_APP_POST_URL;
      // console.log('urlllllllllllll',process.env.REACT_APP_POST_URL);

      const formData = new FormData();

      // Append regular fields to FormData
      formData.append("SingleLine", formValues.SingleLine);
      formData.append(
        "PhoneNumber_countrycode",
        formValues.PhoneNumber_countrycode
      );
      formData.append("Email", formValues.Email);
      formData.append("SingleLine1", formValues.SingleLine1);
      formData.append("Dropdown", formValues.Dropdown);
      formData.append("Currency1", formValues.Currency1);
      formData.append("Currency", formValues.Currency);
      formData.append("Dropdown3", formValues.Dropdown3);
      formData.append("Dropdown2", "New");
      formData.append("Dropdown1", "Website");
      formData.append("SingleLine2", formValues.SingleLine2);

      // Append file fields to FormData
      if (formValues.ImageUpload) {
        formData.append("ImageUpload", formValues.ImageUpload);
      }
      if (formValues.FileUpload) {
        formData.append("FileUpload", formValues.FileUpload);
      }

      try {
        setIsBtnDisable(true);

        await axios.post(postUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setIsSuccessfullySubmitted(true);
        setIsBtnDisable(false);
        setFormValues(INITIAL_FORM_VALUES);
        //  console.log('ressssssssssssssssssssssssssss');

        // if (response) {
        //   console.log('this is response',response);

        //   toast.success("Career Form Submitted");

        //   setIsBtnDisable(false);
        //   setIsSuccessfullySubmitted(true);
        //   setFormValues(INITIAL_FORM_VALUES);
        // }
      } catch (error) {
        console.error("Error:", error);
        toast.error(ERROR_MESSAGE.CAREER_FORM_SUBMISSION);
      } finally {
        setIsSuccessfullySubmitted(true);
        setIsBtnDisable(false);
        setFormValues(INITIAL_FORM_VALUES);
      }
    },
    [formValues, INITIAL_FORM_VALUES]
  );

  const handleInputChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setFormValues({ ...formValues, [name]: value });
    },
    [formValues]
  );

  const handleFileChange = useCallback(
    (event) => {
      const { name, files } = event.target;
      setFormValues({ ...formValues, [name]: files[0] });
    },
    [formValues]
  );

  const handleThankYouComplete = useCallback(() => {
    setIsSuccessfullySubmitted(false);
    onClose();
  }, [onClose]);

  return (
    <PopUpCard
      isOpen={isOpen}
      onClose={onClose}
      className="center-item px-3"
      style={popPupStyle}
      removeBtn={false}
    >
      <div className="career-form position-hc px-3">
        <button style={closeBtnStyle} onClick={onClose}>
          X
        </button>
        <div className="careerFormHeading">
          <div className="px-3 fs-2 text-light center-item">
            <FaHandshake />
          </div>
          <Heading
            text={"Job Application Form"}
            color="#fff"
            fontSize="1.5rem"
            className="fs-6 fs-lg-1 fs-md-1"
          />
        </div>

        <form className="row" onSubmit={handleForm}>
          <div className="col-md-6">
            <InputCustom
              className="px-3 rounded-1"
              placeholder={"Full Name"}
              name="SingleLine"
              value={SingleLine}
              onChange={handleInputChange}
              // required
            />
            {errors.SingleLine && (
              <div className="alertMsg">{errors.SingleLine}</div>
            )}
          </div>

          <div className="col-md-6">
            <InputCustom
              className="px-3 rounded-1"
              name="PhoneNumber_countrycode"
              value={PhoneNumber_countrycode}
              placeholder={"Contact No."}
              onChange={handleInputChange}
              // required
            />
            {errors.PhoneNumber_countrycode && (
              <div className="alertMsg">{errors.PhoneNumber_countrycode}</div>
            )}
          </div>

          <div className="col-md-6">
            <InputCustom
              className="px-3 rounded-1"
              placeholder={"Email"}
              name="Email"
              value={Email}
              onChange={handleInputChange}
            />
            {errors.Email && <div className="alertMsg">{errors.Email}</div>}
          </div>

          <div className="col-md-6">
            <SelectOption
              className="px-3 rounded-1"
              selectText={"Select Experience"}
              data={CAREER_FORM?.EXPERIENCE_OPTIONS}
              name="Dropdown3"
              value={Dropdown3}
              onChange={handleInputChange}
            />
            {/* <InputCustom
              className="px-3 rounded-1"
              placeholder={"Total Experience In Years"}
              name="experience"
              value={experience}
              onChange={handleInputChange}
              type="number"
            /> */}
            {errors.Dropdown3 && (
              <div className="alertMsg">{errors.Dropdown3}</div>
            )}
          </div>

          <div className="col-md-6">
            <InputCustom
              className="px-3 rounded-1"
              placeholder="Current CTC In Lakhs"
              name="Currency"
              value={Currency}
              type="number"
              onChange={handleInputChange}
            />
            {errors.Currency && (
              <div className="alertMsg">{errors.Currency}</div>
            )}
          </div>

          <div className="col-md-6">
            {/* <SelectOption
              className="px-3 rounded-1"
              data={CAREER_FORM?.CURRENT_PACKAGES_OPTIONS}
              selectText={"Select Package"}
              name="expectedPackage"
              value={expectedPackage}
              onChange={handleInputChange}
            /> */}
            <InputCustom
              className="px-3 rounded-1"
              placeholder="Expected CTC In Lakhs"
              name="Currency1"
              value={Currency1}
              type="number"
              onChange={handleInputChange}
            />
            {errors.Currency1 && (
              <div className="alertMsg">{errors.Currency1}</div>
            )}
          </div>

          <div className="col-md-6">
            <InputCustom
              className="px-3 rounded-1"
              placeholder={"Your Current Location"}
              name="SingleLine1"
              value={SingleLine1}
              onChange={handleInputChange}
            />
            {errors.SingleLine1 && (
              <div className="alertMsg mb-2">{errors.SingleLine1}</div>
            )}
          </div>

          <div className="col-md-6">
            {/* <SelectOption
              className="px-3 rounded-1"
              selectText={"Select Job Title"}
              data={CAREER_FORM?.JOB_TITLE_OPTIONS}
              name="jobTitle"
              value={jobTitle}
              onChange={handleInputChange}
            /> */}
            <SelectOption
              className="px-3 rounded-1"
              selectText={"Select Preferred Job Location"}
              data={CAREER_FORM?.LOCATIONS_OPTIONS}
              name="Dropdown"
              value={Dropdown}
              onChange={handleInputChange}
            />
            {errors.Dropdown && (
              <div className="alertMsg mb-1">{errors.Dropdown}</div>
            )}
          </div>

          <div className="row p-0 m-0">
            <div className="col-md-6">
              <div className="selectFileBox">
                <label>Upload Your Photo</label>
                <InputCustom
                  className="px-3 rounded-1 inputFile"
                  placeholder={"Latest Passport Size Photo"}
                  type="file"
                  name="ImageUpload"
                  onChange={handleFileChange}
                  accept="image/*"
                />
              </div>
              {errors.ImageUpload && (
                <div className="alertMsg my-1">{errors.ImageUpload}</div>
              )}
            </div>
            <div className="col-md-6">
              <div className="selectFileBox">
                <label> Upload Your Resume</label>
                <InputCustom
                  className="px-3 rounded-1 inputFile"
                  placeholder={"Upload CV*"}
                  type="file"
                  name="FileUpload"
                  onChange={handleFileChange}
                />
              </div>
              {errors.FileUpload && (
                <div className="alertMsg my-1">{errors.FileUpload}</div>
              )}
            </div>
          </div>

          <ButtonDarkBlue
            name={
              isBtnDisable
                ? PROCESS_MESSAGE.FORM_SUBMISSION_IN_PROGRESS
                : "Submit"
            }
            className={"w-50 center-item rounded-1"}
            style={{
              position: "relative",
              left: "50% ",
              transform: "translateX(-50%)",
              backgroundColor: "#000",
            }}
            disabled={isBtnDisable}
          />
        </form>
        {isSuccessfullySubmitted && (
          <ThankYouMessage onComplete={handleThankYouComplete} />
        )}
      </div>
    </PopUpCard>
  );
};

export default CareerFormPopUp;

// export function getFormData(formValues) {
//   const formData = new FormData();

//   const {
//     name,
//     phone,
//     email,
//     location,
//     preferLocation,
//     expectedPackage,
//     currentPackage,
//     experience,
//     photo,
//     file,
//     jobTitle,
//   } = formValues || EMPTY_OBJECT;

//   formData.append("SingleLine", name);
//   formData.append("PhoneNumber_countrycode", phone);
//   formData.append("Email", email);
//   formData.append("Dropdown3", experience);
//   formData.append("Currency", currentPackage); // Current CTC
//   formData.append("Currency1", expectedPackage); // Expected CTC
//   formData.append("SingleLine1", location); // Current Location
//   formData.append("SingleLine2", jobTitle); //job SingleLine2
//   formData.append("Dropdown", preferLocation); // Preferred Job Location
//   formData.append("Dropdown1", "Website"); // Source Website
//   formData.append("Dropdown2", "New"); // Candidate Status
//   if (photo) formData.append("ImageUpload", photo);
//   if (file) formData.append("FileUpload", file);

//   return formData;
// }

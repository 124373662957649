import ConvertStringToHtml from "../../../../globalComponents/ConvertStringToHtml";
import ProjectHighlight from "./ProjectHighlight";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { formatIndianCurrency } from "../../../../../helpers/helper";
import { EMPTY_OBJECT } from "../../../../../assets/constants";
import "./overview.css";

const Overview = ({ projectData = EMPTY_OBJECT }) => {
  const {
    Project_Name,
    Description,
    Address,
    RegNo = "",
    Max_Price,
    Min_Price,
    category,
  } = projectData;

  const price = `₹ ${formatIndianCurrency(
    Min_Price
  )} - ₹ ${formatIndianCurrency(Max_Price)} *`;

  const highlightData = {
    "project Type": category?.data?.attributes?.Title || "",
    price: price || "",
    address: Address || "",
  };

  const { pathname } = useLocation();
  console.log("Current pathname:", pathname); // Debugging the pathname

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Why Should I invest in Orion 132?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Orion One 32 has a premium and central location, great connectivity, huge customer catchment, great price appreciation, and high and assured rentals powered by Freeport Retail with 29 years lease guarantee.",
        },
      },
      {
        "@type": "Question",
        name: "Is Orion One 32 RERA approved?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes! The RERA number of Orion One 32 is UPRERAPRJ960554.",
        },
      },
      {
        "@type": "Question",
        name: "Where is Orion One 32 Located in Noida?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Orion One 32 is located in Sector 132 of Noida.",
        },
      },
      {
        "@type": "Question",
        name: "Does Orion 132 offer good connectivity?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "It’s located bang on Noida Expressway with an upcoming metro (proposed) connectivity.",
        },
      },
    ],
  };

  console.log("FAQ Schema being added to Helmet:", faqSchema);

  return (
    <>
      {/* Helmet is used to add FAQ schema dynamically to the head */}
      {pathname === "/noida/orion-one-32" ? (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(faqSchema)}
          </script>
        </Helmet>
      ) : (
        console.warn("Pathname does not match, FAQ schema not added")
      )}

      {Description && (
        <section className="row overview">
          <ConvertStringToHtml htmlData={Description} />
        </section>
      )}

      <ProjectHighlight
        projectName={Project_Name}
        highlightData={highlightData}
        regNo={RegNo}
      />
    </>
  );
};

export default Overview;

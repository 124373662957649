import { useEffect, useState } from "react";
// import { PiSealCheckFill } from "react-icons/pi";
import PropTypes from "prop-types";
import Emoji from "../../../assets/emoji.gif";
// import "./thankYouMessage.css";

const ThankYouMessage = ({ onComplete }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const thankMsgTimer = setTimeout(() => {
      setIsVisible(false);
      if (typeof onComplete === "function") {
        onComplete();
      }
    }, 3000);

    return () => clearTimeout(thankMsgTimer);
  }, [onComplete]);

  if (!isVisible) return null;

  return (
    <div className="text-center">
      <img src={Emoji} alt="Emoji" height="100" width="100" />
      <h3>Great to hear from you. We appreciate your response!</h3>
    </div>
  );
};

export default ThankYouMessage;

ThankYouMessage.propTypes = {
  onComplete: PropTypes.func,
};
// import { useCallback, useState } from "react";

// import ThankYouMessage from "../ThankYouMessage";
// import ButtonDarkBlue from "../molecules/ButtonDarkBlue";

// import { submissionOnZoho } from "../../../assets/constants/zoho";
// import { FORM_NAMES } from "../../../assets/constants/formNames";
// import { PROCESS_MESSAGE } from "../../../assets/constants/messages";
// import { ALL_CITIES } from "../../../assets/constants/cities";
// import "./queryForm2.css";

// const INITIAL_FORM_VALUES = {
//   formName: FORM_NAMES?.ANY_ENQUIRIES_FORM_NAME,
//   name: "",
//   phone: "",
//   email: "",
//   propertyType: "",
//   cityName: "",
//   message: "",
// };

// const QueryForm2 = () => {
//   const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);
//   const [errors, setErrors] = useState({});
//   const [isBtnDisable, setIsBtnDisable] = useState(false);
//   const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

//   const handleForm = useCallback(
//     async (event) => {
//       event.preventDefault();
//       const validationErrors = validateForm(formValues);
//       if (Object.keys(validationErrors).length === 0) {
//         setIsBtnDisable(true);
//         // toast("form submitted successfully");
//         await submissionOnZoho(formValues);
//         setIsBtnDisable(false);
//         setIsSuccessfullySubmitted(true);
//         setFormValues(INITIAL_FORM_VALUES);
//       } else {
//         setErrors(validationErrors);
//       }
//     },
//     [formValues]
//   );

//   const handleInputChange = useCallback(
//     (event) => {
//       const { name, value } = event.target;
//       setFormValues({ ...formValues, [name]: value });
//     },
//     [formValues]
//   );

//   const handleThankYouComplete = useCallback(
//     () => setIsSuccessfullySubmitted(false),
//     []
//   );

//   return (
//     <div className="center-item ">
//       <div
//         className="QueryForm-size py-4 w-100"
//         style={{ position: "relative" }}
//       >
//         <div className="queryFormBox px-4">
//           <form onSubmit={handleForm}>
//             <div className="">
//               <div className="w-100 mb-3">
//                 <input
//                   type="text"
//                   name="name"
//                   id="name"
//                   placeholder="Full Name"
//                   className="QueryFormInput"
//                   value={formValues.name}
//                   onChange={handleInputChange}
//                   required
//                 />
//                 {errors.name && <span className="alertMsg">{errors.name}</span>}
//               </div>

//               <div className="w-100 mb-3">
//                 <input
//                   type="text"
//                   name="phone"
//                   id="phone"
//                   placeholder="Phone Number"
//                   className="QueryFormInput"
//                   value={formValues.phone}
//                   onChange={handleInputChange}
//                   required
//                 />
//                 {errors.phone && (
//                   <span className="alertMsg">{errors.phone}</span>
//                 )}
//               </div>
//             </div>

//             <div className="mb-3">
//               <input
//                 type="email"
//                 name="email"
//                 id="email"
//                 placeholder="Enter Your Email"
//                 className="QueryFormInput"
//                 value={formValues.email}
//                 onChange={handleInputChange}
//                 required
//               />
//               {errors.email && <span className="alertMsg">{errors.email}</span>}
//             </div>

//             <div className="mb-3">
//               <select
//                 name="propertyType"
//                 id="propertyType"
//                 value={formValues.propertyType}
//                 onChange={handleInputChange}
//                 className="QueryFormInput"
//                 required
//               >
//                 <option value="">Property Type</option>
//                 <option value="Residential">Residential</option>
//                 <option value="Commercial">Commercial</option>
//                 <option value="Other">Other</option>
//               </select>
//               {errors.propertyType && (
//                 <span className="alertMsg">{errors.propertyType}</span>
//               )}
//             </div>

//             <div className="mb-3">
//               <select
//                 name="cityName"
//                 id="cityName"
//                 value={formValues.cityName}
//                 onChange={handleInputChange}
//                 className="QueryFormInput"
//                 required
//               >
//                 <option value="">City Name</option>
//                 {ALL_CITIES.map((city, idx) => {
//                   return (
//                     <option key={idx} value={city}>
//                       {city}
//                     </option>
//                   );
//                 })}
//               </select>
//               {errors.cityName && (
//                 <span className="alertMsg">{errors.cityName}</span>
//               )}
//             </div>

//             <div className="mb-3">
//               <textarea
//                 placeholder="Message..."
//                 rows="5"
//                 cols="30"
//                 name="message"
//                 className="QueryFormTextArea"
//                 value={formValues.message}
//                 onChange={handleInputChange}
//                 required
//               />
//               {errors.message && (
//                 <span className="alertMsg">{errors.message}</span>
//               )}
//             </div>

//             <div>
//               <ButtonDarkBlue
//                 type="submit"
//                 className="rounded-1 queryFormSubmitBtn"
//                 disabled={isBtnDisable}
//                 name={
//                   isBtnDisable
//                     ? PROCESS_MESSAGE.FORM_SUBMISSION_IN_PROGRESS
//                     : "Submit Now"
//                 }
//               />
//             </div>
//           </form>
//         </div>
//         {isSuccessfullySubmitted && (
//           <ThankYouMessage onComplete={handleThankYouComplete} />
//         )}
//       </div>
//     </div>
//   );
// };

// export default QueryForm2;

// const validateForm = (formValues) => {
//   const errors = {};

//   if (!formValues.name.trim()) {
//     errors.name = "Full Name is required";
//   }

//   if (!formValues.phone.trim()) {
//     errors.phone = "Phone Number is required";
//   } else if (formValues.phone.length !== 10) {
//     errors.phone = "Phone Number must be exactly 10 digits";
//   } else if (!/^\d{10}$/.test(formValues.phone)) {
//     errors.phone = "Phone Number must be numeric and 10 digits";
//   }

//   if (!formValues.email.trim()) {
//     errors.email = "Email is required";
//   } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
//     errors.email = "Email is invalid";
//   }

//   if (!formValues.propertyType) {
//     errors.propertyType = "Property Type is required";
//   }

//   if (!formValues.cityName) {
//     errors.cityName = "City Name is required";
//   }

//   if (!formValues.message.trim()) {
//     errors.message = "Message is required";
//   }

//   return errors;
// };

export function validateCareerForm(formData) {
  const newErrors = {};
  const {
    SingleLine,
    PhoneNumber_countrycode,
    Email,
    SingleLine1,
    Dropdown,
    Currency1,
    Currency,
    Dropdown3,
    ImageUpload,
    FileUpload,
  } = formData;

  if (!SingleLine.trim()) newErrors.SingleLine = "Name is required.";

  if (!PhoneNumber_countrycode.trim()) {
    newErrors.PhoneNumber_countrycode = "Phone number is required.";
  } else if (!/^\d{10}$/.test(PhoneNumber_countrycode)) {
    newErrors.PhoneNumber_countrycode = "Phone number must be exactly 10 digits.";
  }

  if (!Email.trim()) {
    newErrors.Email = "Email is required.";
  } else if (!/\S+@\S+\.\S+/.test(Email)) {
    newErrors.Email = "Invalid email address.";
  }

  if (!SingleLine1.trim()) newErrors.SingleLine1 = "Location is required.";

  if (!Dropdown.trim())
    newErrors.Dropdown = "Please select a preferred location.";

  if (Dropdown3 === null || Dropdown3 === "") {
    newErrors.Dropdown3 = "Experience is required.";
  }

  if (Currency === "") {
    newErrors.Currency = "Current package is required.";
  }

  if (Currency1 === "") {
    newErrors.Currency1 = "Expected package is required.";
  }

  if (!ImageUpload) newErrors.ImageUpload = "Please upload your photo.";
  if (!FileUpload) newErrors.FileUpload = "Please upload your resume.";

  return newErrors;
}

export default validateCareerForm;

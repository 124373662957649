import CustomLink from "../../CustomLink/CustomLink";
import { ButtonDarkBlue } from "./../../globalComponents/molecules/ButtonDarkBlue";
import Luxury from "../../../assets/Luxury.png";
import Mid from "../../../assets/MidRange.png";
import Affordable from "../../../assets/Affordable.png";
import Ready from "../../../assets/ReadytoMove.png";

import URL, { URL_PARAMS } from "../../../assets/constants/url";
import "./finalexplore.css";

const cardData = [
  {
    title: "Luxury",
    imageUrl: Luxury,
  },
  {
    title: "MID-RANGE",
    imageUrl: Mid,
  },
  {
    title: "AFFORDABLE",
    imageUrl: Affordable,
  },
  {
    title: "READY TO MOVE IN",
    imageUrl: Ready,
  },
];

const FinalExplore = () => {
  // const isMobile = useDeviceSize();
  return (
    <div className="container bgFinalExplore">
      <div className="row center-item">
        {cardData.map((card, idx) => (
          <div
            className="col-6 col-lg-3 my-md-3 col-md-6 center-item col-sm-6 col-xs-6 p-1"
            key={idx}
            style={
              {
                // width: isMobile ? "50%" : "auto",
                // margin: isMobile ? "0" : ".5rem",
              }
            }
          >
            <ExploreCard data={card} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FinalExplore;

function ExploreCard({ data, className }) {
  const { title, imageUrl } = data;
  return (
    <div
      className={`card ${className}`}
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <h2 className="headingExplore">{title}</h2>
      <CustomLink
        className=""
        to={`${URL.SEARCH}?${URL_PARAMS.CATEGORY}=${title}`}
      >
        <ButtonDarkBlue className="exploreViewsDetailsBtn1 " name="Explore" />
      </CustomLink>
    </div>
  );
}

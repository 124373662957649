import styles from "./eventTimeZone.module.css";

const EventTimeZone = ({ className, icon: Icon, title, text, time }) => {
  return (
    <div className={` ${className} ${styles.eventTimeZoneContainer}`}>
      <div className={styles.iconTextWrapper}>
        {Icon && (
          <div className=" d-flex align-items-center gap-2">
            <Icon className={styles.icon} />
            {title && <div className={styles.textTitle}>{title}</div>}
          </div>
        )}
        <div className={styles.textContainer}>
          <div className={styles.textDescription}>
            {text}
            {","}
            {time}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventTimeZone;

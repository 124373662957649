import TextBg from "../../globalComponents/molecules/TextBg";
import MapSection from "./ContactUsComponents/MapSection";
import AddressSection from "./ContactUsComponents/AddressSection";
import useDeviceSize from "../../../hooks/useDeviceSize";
// import Heading from "../globalComponents/molecules/Heading";
import Heading from "../../globalComponents/molecules/Heading";

const ContactUsPage = () => {
  const isMobile = useDeviceSize();
  return (
    <section className="container">
      <Heading
        text={"Our Branches"}
        color="black"
        fontWeight={700}
        className="text-center p-4"
      />
      {/* <TextBg text={"OUR BRANCHES"} fontWeight={900} fontSize="6rem" /> */}
      <AddressSection />
      <MapSection />

      {isMobile}
    </section>
  );
};
export default ContactUsPage;

import { ButtonDarkBlue } from "./../molecules/ButtonDarkBlue";
import WishListBtn from "../WishListBtn";

import { formatIndianCurrency } from "../../../helpers/helper";
import { handleViewMore } from "./liveCount.services";
import fireFlame from "../../../assets/fireFlame.jpg";
import styles from "./card.module.css";

const Card = ({ cardData }) => {
  const {
    Project_Name,
    Min_Price,
    Max_Price,
    Address,
    cityName,
    propertyId,
    mobileImg,
    altTag,
    imgTitle,
  } = cardData;
  const minPrice = formatIndianCurrency(Min_Price);
  const maxPrice = formatIndianCurrency(Max_Price);

  // const { img, city, type, price, details, name } = cardData;

  const [name, city, type, price, details] = [
    Project_Name,
    cityName,
    "Residential",
    minPrice + " - ₹ " + maxPrice,
    Address,
  ];

  return (
    <div className={styles.card1}>
      <div className={styles.imgDiv} onClick={() => handleViewMore(propertyId)}>
        <div className={styles.favouriteBtn}>
          <WishListBtn cardData={cardData} />
        </div>
        {/* <div>For sale</div> */}
        <div style={{ height: "270px" }}>
          <img alt={altTag} src={mobileImg} title={imgTitle} loading="lazy" />
        </div>
        <div className={styles.signatureContainer}>
          {/* WEALTH-CLINIC.Signature */}

          <img
            className={styles.fireFlame}
            alt="flame"
            src={fireFlame}
            loading="lazy"
          />
        </div>
      </div>
      <div
        className="fw-bold d-flex justify-content-between"
        style={{ color: "#212529" }}
      >
        <div className="text-wrap">
          {name} | <span>{city}</span>
        </div>

        {/* <div>
          <i className="bi bi-star c-gray d-none d-md-block"></i>4.94
        </div> */}
      </div>
      <div className="c-gray text-wrap" style={{ color: "#333" }}>
        {details}
      </div>
      <div className="">
        <div className="col-md-5 text-wrap">
          <div className="c-gray" style={{ color: "#333" }}>
            {type}
          </div>
          <div className="fw-bold" style={{ color: "#212529" }}>
            ₹ {price}
          </div>
        </div>
        <div className="col-md-12">
          <ButtonDarkBlue
            name="View More"
            className={styles.propertiesViewMoreBtn}
            // onClick={(event) => event.stopPropagation()}
          />
        </div>
      </div>
    </div>
  );
};

export default Card;

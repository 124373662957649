// import AboutImg from "./assest/Career_Wc.webp";
import AboutImg1 from "./assest/Career_Wc_New.webp";
import "./lifeAtWc.css";

const LifeAtWc = ({ data }) => {
  return (
    <div className="mb-5">
      <div className="row careerPageContent">
        <div className="careerPageImgContainer vertical-center  col-12 col-lg-5 col-md-12  col-sm-12 col-xs-12 ">
          <img
            className="aboutImg rounded-3"
            src={AboutImg1}
            alt="career img"
          />
        </div>
        <div className="col-12 col-lg-7  col-sm-12 col-xs-12">
          <p className="aboutDesc">
            We at Wealth Clinic focus on a positive environment that is
            productive for everyone and build around a healthy space.
            Professional and personal growth plays a crucial aspect in the
            organization’s environment and to keep them motivated we conduct
            various activities that include: Come, be a part of our growing
            family!
          </p>
          <div className="row">
            <ActivityCard
              number={1}
              description={
                "We conduct induction programs for he new joiners in order to understand the people and pace of the organization."
              }
            />

            <ActivityCard
              number={2}
              description={
                "We constantly conduct training and development programs for our employees to up skill themselves."
              }
            />
          </div>

          <p className="aboutDesc1">
            To motivate the morale of our employees, we run various rewards and
            recognition programs too.<br></br>
            Come, be a part of our growing family!
            <br></br>
            We believe that long-term growth needs long-term relationships. And
            it is as true for our team as for our clients. This is why Wealth
            Clinic recognizes, nurtures, and invests in talents and builds a
            long-term relationship with them for a better future outcome. So, if
            you have it in you to make it big in this sector and equally value
            job stability and long-term association, this is the workplace for
            you.
            <br></br>
            Connect with our talent acquisition team at hr@wealth-clinic.com
          </p>
        </div>
      </div>
    </div>
  );
};
export default LifeAtWc;

const ActivityCard = ({ number, description }) => {
  return (
    <div className="col-12 col-sm-6">
      <div className="bgCards activityCard">
        <h3>
          <span className="bgNumber">{number}. </span>
        </h3>
        <p className="paraDesc">{description}</p>
      </div>
    </div>
  );
};

// import { toast } from "react-toastify";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import InfiniteSlides from "../../globalComponents/slickCrousel/InfiniteSlides";
import Heading from "../../globalComponents/molecules/Heading";

import useApiFetcher from "../../../hooks/useApiFetcher";
import { getFullUrl } from "../../../assets/constants/apiUrls";
import { propertyParams } from "../../../helpers/params";
import { submissionOnZoho } from "../../../assets/constants/zoho";
// import QueryForm2 from "../../globalComponents/queryForm2/QueryForm2";
import { FORM_NAMES } from "../../../assets/constants/formNames";
// import { SLIDES } from "../../../assets/IMG";
import { PROCESS_MESSAGE } from "../../../assets/constants/messages";
import "./aboutUs.css";
import { useCallback } from "react";
import ThankYouQuery2 from "../../../components/globalComponents/queryForm2/ThankYouQuery2";
const settings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  speed: 500,
};

const AboutUs = () => {
  const url = getFullUrl(
    "/api/projects?populate[0]=Image&pagination[limit]=10&filters[newLaunches]=true"
  );
  const [projects, error, isLoading] = useApiFetcher(url);

  if ((!projects, isLoading)) return null;
  if (error) return <div>{error}</div>;

  const SLIDES = formatProjectsImg(projects);

  return (
    <div className="container  d-flex align-items-center justify-content-center flex-wrap callBackForm gap-lg-5">
      <div className="col-md-7 my-2 newClass">
        <div className="text-Item">
          <Heading
            text={"Request a Call Back"}
            className="callback text-center"
            fontWeight={700}
            color="var(--global-heading-color)"
          />
          <p className=" text-center mt-2">
            Looking for a home that brings happiness or property that brings
            prosperity?
            <br /> Contact us - Get Real Estate with Unreal Deals!
          </p>
        </div>
        <div className="d-none d-md-block">
          <InfiniteSlides
            settings={settings}
            data={SLIDES}
            className="about-Us-Page-Slider"
          />
        </div>
      </div>

      <div className="col-md-4 mt-4">
        {/* <QueryForm2 /> */}
        <EnquiryForm />
      </div>
    </div>
  );
};

function formatProjectsImg(projects) {
  return projects?.map((project) => {
    const { img, altTag, imgTitle } = propertyParams(project);
    return {
      img,
      alt: altTag,
      title: imgTitle,
    };
  });
}

export default AboutUs;

export const EnquiryForm = () => {
  const [formName] = useState(FORM_NAMES?.ANY_ENQUIRIES_FORM_NAME);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

  const validateForm = () => {
    const validationErrors = {};

    if (!name.trim()) {
      validationErrors.name = "Full Name is required";
    }

    if (!phone.trim()) {
      validationErrors.phone = "Mobile Number is required";
    } else if (!/^\d{12}$/.test(phone.replace(/\D/g, ""))) {
      validationErrors.phone = "Mobile Number must be 10 digits";
    }

    return validationErrors;
  };
  const handleFormSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length === 0) {
        setIsBtnDisable(true);
        await submissionOnZoho({ formName, name, phone });
        setIsBtnDisable(false);
        setIsSuccessfullySubmitted(true);
        setName("");
        setPhone("");
      } else {
        setErrors(validationErrors);
      }
    },
    [formName, name, phone]
  );
  // const handleInputChange = async (event) => {
  //   const { name, value } = event.target;
  //   setFormValues({ ...formValues, [name]: value });
  // };

  // const handlePhoneChange = (value) => {
  //   setFormValues((prevValues) => ({ ...prevValues, phone: value }));
  // };

  const handleThankYouComplete = () => setIsSuccessfullySubmitted(false);
  return (
    <div
      className="p-4"
      style={{
        // backgroundColor: "rgba(38, 38, 38, 0.73)",
        borderRadius: "20px",
        width: "100%",

        border: "1px solid #ccc",
        boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.25)",
      }}
    >
      <h2
        className="text-center mb-4"
        style={{ fontWeight: "bold", fontFamily: "Poppins" }}
      >
        Contact Us
      </h2>
      {isSuccessfullySubmitted ? (
        <ThankYouQuery2 onComplete={handleThankYouComplete} />
      ) : (
        <form onSubmit={handleFormSubmit}>
          <div>
            <input
              name="name"
              type="text"
              required
              className="border-bottom border-none  mb-3 pb-1 px-2"
              value={name}
              placeholder="Name"
              style={{
                background: "none",
                width: "100%",
                border: "none",
                outline: "none",
              }}
              onChange={(e) => setName(e.target.value)}
            />
            {errors.name && <span className="alertMsg">{errors.name}</span>}
          </div>

          <div className="">
            <PhoneInput
              className="border-bottom border-none  mb-3 pb-1"
              name="phone"
              country={"in"}
              value={phone}
              onChange={(phone) => setPhone(phone)}
              // enableSearch={true}
              // disableSearchIcon={true}
              // disableCountryCode={true}
              countryCodeEditable={false}
              placeholder=""
              style={{
                background: "none",
                width: "100%",
                border: "none",
                borderBottom: "1px solid white ",
                outline: "none",
              }}
            />
            {errors.phone && <span className="alertMsg">{errors.phone}</span>}
          </div>

          <button
            type="submit"
            className="btn p-2 w-100"
            style={{
              backgroundColor: "#ef750f",
              color: "white",
              fontWeight: "bold",
              borderRadius: "25px",
            }}
            disabled={isBtnDisable || !phone || !name}
          >
            {isBtnDisable
              ? PROCESS_MESSAGE.FORM_SUBMISSION_IN_PROGRESS
              : "Submit"}
          </button>
        </form>
      )}
    </div>
  );
};

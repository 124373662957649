import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Scrollbar, Zoom } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import sumit_sharma from "../../assets/Sumit-Sharma.png";
import Sandeep_srivastava from "../../assets/Sandeep-Srivastava.png";
import parul_singh from "../../assets/Parum-Singh.png";
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
const ClientCard = ({ card }) => (
  <div className="p-4 pt-5 d-flex align-items-center justify-content-center">
    <div
      className="card text-center bg-white shadow"
      style={{ height: "300px", cursor: "pointer" }}
      onClick={() =>
        window.open(
          "https://www.google.com/search?hl=en-IN&gl=in&q=1401,+4th+Floor,+Wealth+Clinic+Pvt.+Ltd.,+Tower-1,+Express+Trade+Tower,+2,+B-36,+Block+B,+Sector+132,+Noida,+Uttar+Pradesh+201301&ludocid=14966508337579957575&lsig=AB86z5Vbd4rKLr2uMr0R2kD4iO#lrd=0x390ce70438c07157:0xcfb3b8097d601d47,3",
          "_blank"
        )
      }
    >
      <div className="card-body position-relative">
        <div className="mb-4">
          <img
            src={card.image}
            alt="client"
            className="rounded-circle border border-secondary"
            style={{
              width: "80px",
              height: "80px",
              position: "absolute",
              top: "-40px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          />
        </div>
        <p className="card-text text-secondary mt-5">{card.review}</p>
        <h5 className="card-title font-weight-bold">{card.name}</h5>
        <div className="rating" style={{ color: "#f39c12", marginTop: "10px" }}>
          {Array.from({ length: 5 }, (_, i) => (
            <FaStar key={i} color={i < card.rating ? "#f39c12" : "#ccc"} />
          ))}
          {/* {"★".repeat(card.rating)}
          {(<CiStar />).repeat(5 - card.rating)} */}
          {/* <CiStar style={{ color: "black" }} /> */}
        </div>
      </div>
    </div>
  </div>
);

const ClientsReviewComponent = () => {
  const clientCards = [
    {
      name: "Parul Singh",
      review:
        "The team culture is fantastic. Everyone is supportive and willing to help, which fosters a sense of community. Regular team-building activities keep morale high.",
      image: parul_singh,
      rating: 4,
    },
    {
      name: "Sandeep Srivastava",
      review:
        "Great team of real estate professionals who assist you at every stage and provide really valuable information and great deals on property investments.",
      image: Sandeep_srivastava,
      rating: 5,
    },
    {
      name: "Sumit Sharma",
      review:
        "Working with Wealth Clinic is an outstanding experience. Training provided here has changed my personality completely, and I feel more confident.",
      image: sumit_sharma,
      rating: 3,
      link: "https://example.com/sumit",
    },
    {
      name: "Sandeep Srivastava",
      review:
        "Great team of real estate professionals who assist you at every stage and provide really valuable information and great deals on property investments.",
      image: Sandeep_srivastava,
      rating: 5,
    },
  ];

  const swiperRef = useRef(null);

  const handleScale = (swiper) => {
    const slides = document.querySelectorAll(".swiper-slide");
    slides.forEach((slide, index) => {
      slide.style.transform =
        index === swiper.activeIndex ? "scale(1.1)" : "scale(1)";
      slide.style.transition = "transform 0.3s ease-in-out";
    });
  };

  useEffect(() => {
    if (swiperRef.current) {
      handleScale(swiperRef.current);
    }
  }, []);

  return (
    <div className="container text-center mt-5 pb-5">
      <h2 className="mb-4 fw-bold text-center">What Our Customers Say</h2>

      <div className="mt-4">
        <Swiper
          breakpoints={{
            640: {
              slidesPerView: 1,
              centeredSlides: true,
            },
            768: {
              slidesPerView: 2,
              centeredSlides: true,
            },
            1024: {
              slidesPerView: 3,
              centeredSlides: true,
            },
          }}
          loop={true}
          speed={2000}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          scrollbar={{ draggable: false }}
          modules={[Autoplay, Zoom, Scrollbar, Pagination]}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            handleScale(swiper);
          }}
          onSlideChange={(swiper) => handleScale(swiper)}
        >
          {clientCards.map((card, i) => (
            <SwiperSlide key={i}>
              <ClientCard card={card} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="mt-4">
        <button
          onClick={() => {
            swiperRef.current?.slidePrev();
            handleScale(swiperRef.current);
          }}
          className="btn border border-2 border-black rounded-circle me-2 bg-none"
        >
          &lt;
        </button>
        <button
          onClick={() => {
            swiperRef.current?.slideNext();
            handleScale(swiperRef.current);
          }}
          className="btn border border-2 border-black rounded-circle bg-none"
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default ClientsReviewComponent;

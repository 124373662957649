import { Link } from "react-router-dom";
// import { IoIosMailOpen } from "react-icons/io";
import { MdPhone } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import Heading from "../../../globalComponents/molecules/Heading";
import useDeviceSize from "../../../../hooks/useDeviceSize";

import styles from "./addressCard.module.css";

const AddressCardNew = ({ data, heading }) => {
  const nodiaStyles = {
    backdropFilter: "blur(20px)",
    backgroundColor: "rgb(0 0 0 / 5%);",
    color: "white",
    padding: "40px 3px",
    borderRadius: "19px",
    boxShadow: "rgb(0 0 0 / 5%) 0px 8px 30px",
    maxWidth: "481px",
  };
  const locationStyles = {
    textAlign: "left",
    paddingLeft: "33px",
  };

  // const { name, address, hrPhone, email, enquiryPhone, background } = data;
  const { background } = data;

  const {
    main_heading = "",
    Address = "",
    Branch_Name = "",
    Contact = "",
    HR_Contact = "",
    Email = "",
    locationLink = "",
  } = data;

  const [address, name, enquiryPhone, hrPhone, email] = [
    Address,
    Branch_Name,
    Contact,
    HR_Contact,
    Email,
  ];

  const isMobile = useDeviceSize();

  const handleClick = () => {
    // window.location.href = locationLink;
    locationLink && window.open(locationLink, "_blank");
  };

  return (
    <>
      <div className="col-lg-6 mb-4 col-12">
        <div className={`location-card  ${styles.bgAdd}`}>
          <div style={nodiaStyles}>
            <Heading
              text={main_heading || name}
              fontWeight="600"
              fontSize="1.5rem"
              lineHeight="2rem"
              className="text-center mb-3 text-white"
            />
            <p className={styles.fullAddress} onClick={handleClick}>
              <div className="d-flex flex-row ">
                <div class="me-1">
                  {" "}
                  <CiLocationOn />
                </div>
                <div class=""> {address}</div>
              </div>
            </p>
            <div style={locationStyles}>
              {" "}
              <p className="mb-2">
                <MdPhone /> Property Inquiry-{" "}
                <a
                  href={`tel:${enquiryPhone}`}
                  className="text-decoration-none text-white"
                >
                  {enquiryPhone}
                </a>
              </p>
              <p className="mb-2">
                <MdPhone /> HR-{" "}
                <a
                  href={`tel:${hrPhone}`}
                  className="text-decoration-none text-white"
                >
                  {hrPhone}
                </a>
              </p>
              <p className="mb-2">
                <CiMail /> Email-{" "}
                <a
                  href={`mailto:${email}`}
                  className="text-decoration-none text-white"
                >
                  {email}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
    // <div className="col-md-4">
    //   <div
    //     className={styles.bgAdd}
    //     style={{
    //       background,
    //     }}
    //   >
    //     <Heading
    //       text={main_heading}
    //       fontWeight="600"
    //       fontSize={isMobile ? "1.25rem" : "2rem"}
    //       lineHeight={isMobile ? "1.75rem" : "2.5rem"}
    //       color="#EF750F"
    //       className="center-item"
    //     />

    //     <h3 className={styles.mainAddressHeading}>{name}</h3>
    //     <hr className={styles.borderNow} />
    //     <p className={styles.fullAddress} onClick={handleClick}>
    //       {address}
    //     </p>
    //     <Link to={locationLink} />
    //     {/* <center>
    //       <img className={styles.addressCardImg} alt="" src={imageUrl} />
    //     </center> */}
    //     <p className={styles.addressIconContainer}>
    //       <span>
    //         <p>
    //           <MdWifiCalling2 />{" "}
    //           <a className={styles.socialIconDeco} href={"tel:" + hrPhone}>
    //             HR : {hrPhone}
    //           </a>
    //         </p>
    //       </span>
    //       <span>
    //         <p>
    //           <IoIosMailOpen />{" "}
    //           <a className={styles.socialIconDeco} href={"mailto:" + email}>
    //             {email}
    //           </a>
    //         </p>
    //       </span>
    //       <span>
    //         <p>
    //           <MdWifiCalling2 />{" "}
    //           <a className={styles.socialIconDeco} href={"tel:" + enquiryPhone}>
    //             Property Enquiry : {enquiryPhone}
    //           </a>
    //         </p>
    //       </span>
    //     </p>
    //   </div>
    // </div>
  );
};

export default AddressCardNew;

import Heading from "../../../globalComponents/molecules/Heading";
import "./sideContainer.css";
import { LuCalendarDays } from "react-icons/lu";
const SideContainer = ({ data }) => {
  const { img, name, price, details } = data;
  // const SIDE_IMG =
  //   "https://www.wealth-clinic.com/property_media/52251706163372.png";
  return (
    // <div className="col-md-12 my-1 d-flex justify-content-center ">
    //   <div className="row side-bar-main m-0 p-0">
    //     <div className="col-md-5 col-md-5  side-bar-container">
    //       <img src={img} alt="" className="img-fluid" />
    //     </div>
    //     <div className="col-lg-7 col-md-7  pt-2 sideInfoContainer">
    //       <Heading
    //         text={name}
    //         fontSize="0.75rem"
    //         color="#E5750F"
    //         lineHeight="1rem"
    //         showLine="1"
    //         className="col-md-12 overflow-hidden"
    //       />
    //       <Heading text={price} fontSize="0.75rem" color="#888" />
    //       <Heading text={details} fontSize="0.75rem" color="#000" />
    //     </div>
    //   </div>
    // </div>
    <div className="  d-flex flex-column my-2 ">
      <div
        className="d-flex  row m-0 p-0 border border-2 rounded bg-white  "
        style={{ height: "120px" }}
      >
        <div className="col-6 p-0  rounded-start h-100">
          <img
            src={img}
            alt=""
            className="rounded-start  img-fluid h-100 w-100"
          />
        </div>
        <div className="col-6 p-0  pt-2  px-0 d-flex flex-column gap-3">
          <div className=" d-flex flex-column gap-1 align-items-center flex-start px-2">
            {/* <LuCalendarDays style={{ color: "#ef750f", fontSize: "13px" }} /> */}
            {/* <Heading
              text={name}
              fontSize=".7rem"
              color="black"
              className="fw-bold"
            />
            <Heading text={details} fontSize=".6rem" color="black" /> */}
            <p
              className="text-black  fw-bold m-0 w-100 blog-recent-post-text"
              style={{ fontSize: "8px" }}
            >
              {name}
            </p>
            <p
              className="text-black  text-black m-0  w-100"
              style={{ fontSize: "8px" }}
            >
              {details}
            </p>
          </div>
          <div
            className="d-flex align-items-center justify-content-center rounded-pill rounded p-2 py-1 mx-2"
            style={{ backgroundColor: "#ef750f" }}
          >
            <p
              className="text-black  text-white m-0"
              style={{ fontSize: "7px" }}
            >
              {price}
            </p>
          </div>
          {/* <div>
          <Heading
            text={title}
            fontSize=".75rem"
            color="black"
            lineHeight="1rem"
            showLine="2"
            fontWeight="500"
            className="text-truncate"
          />
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default SideContainer;
